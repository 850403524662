import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserHeader from "../Header/UserHeader/UserHeader";
import "../HomeScreen/Dashboard/dashboard.scss";
import { FaBars } from "react-icons/fa";
import { Button } from "semantic-ui-react";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

const Test = ({ handleToggleSidebar }) => {

  const [testToken, setTestToken] = useState();
  const [spin, setSpin] = useState(false);
  const [testRes, setTestRes] = useState(null);
  const [openStartButton, setOpenStartButton] = useState(null);
 

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {

    getTime()
    getTestResult();

    const intervalId = setInterval(getTime, 5000);
    return () => clearInterval(intervalId);
  }, []);


  async function getTime() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,

      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.INDIAN_TIME,
        bodyParam
      );

      const testStartTime = moment(userDetails.testData.start_time, "YYYY-MM-DD h:mm a");
      const testEndTime = moment(userDetails.testData.end_time, "YYYY-MM-DD h:mm a");
     
      var options = {
        timeZone: "Asia/Kolkata",
        hour12: false,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      let indianTimeZoneVal = new Date(testStartTime).toLocaleString(
        "en-US",
        options
      );
      let endndianTimeZoneVal = new Date(testEndTime).toLocaleString(
        "en-US",
        options
      );

     

      if (response.itime >= indianTimeZoneVal &&
        response.itime <= endndianTimeZoneVal) {
        setOpenStartButton(true);
      }

      else {
        setOpenStartButton(false);
      }


    } catch (error) {
      console.error(error);
    }
  }

  async function getTestResult() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        emailid: userDetails.email,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TEST_RESULT,
        bodyParam
      );
      if (response != undefined) {
        if (response[userDetails.testData.test_id]) {
          setTestRes(response[userDetails.testData.test_id]);
        }
      }
      dispatch(awsSignIn(userDetails));
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleButtonClick = async () => {
    let userdata = { ...userDetails };
    setSpin(true);
    try {

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `${Constants.GET_WHEEBOX_TOKEN}?loginId= ${userdata.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setTestToken(response);
      setSpin(false);
      window.open(`https://wgc.wheebox.com/LOGIN-2/verifyWBToken.obj?compCode=1359000&token=${response.token}`, '_blank');

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>

      <div>
        {spin === true ? <div className={{
          position: "fixed",
          backgroundColor: "#000000a1",
          width: "100vw",
          height: "100vh",
          display: "flex",
          top: "0",
          left: "0",
          zIndex: "2000",
        }}><CircularProgress style={{ position: "fixed", top: "48%", left: "48%", background: "transparent", zIndex: "2001" }} /> </div> : null}
        <UserHeader />
        <div style={{ height: "100vh" }}>
          <div>
            <h3 style={{ fontSize: "20px", fontWeight: "bold", color: 'orange' }}>Test Heading</h3>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Test ID :</b> {userDetails.testData.test_id}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Test Name :</b> {userDetails.testData.test_name}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Start :</b> {moment(userDetails.testData.start_time).format("YYYY-MM-DD h:mm a")}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>End :</b> {moment(userDetails.testData.end_time).format("YYYY-MM-DD h:mm a")}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Test Duration :</b> {userDetails.testData.testTotalTime}</p>


            {testRes == null && openStartButton ? (<Button
              style={{
                backgroundColor: "#FF7D00",
                width: "100px",
                height: "40px",
                fontSize: "14px",
                border: "none",
                color: "white",
                marginBottom: "2rem",
                borderRadius: "5px",
              }}
              onClick={handleButtonClick}

            >
              Start
            </Button>) : null}
          </div>
          {testRes !== null ? (<div>
            <br></br>
            <h3 style={{ fontSize: "20px", fontWeight: "bold", color: 'orange' }}>Test Result</h3>
            <br></br>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Total Questions :</b> {testRes.totalQuestion}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Total Questions Attempted :</b> {testRes.totalQuestionAttempted}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Correct Attempted :</b> {testRes.correctAttempt}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Incorrect Attempted :</b> {testRes.incorrectAttempt}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Marks Obtained :</b> {testRes.obtainMark}</p>
            <p style={{ fontSize: "18px", margin: "1rem 0rem" }}><b>Percentage :</b> {testRes.percentage}</p>
          </div>) : null}
        </div>
      </div>
    </main>
  );
};

export default Test;
