import "./Formative.scss";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../../modules/Window/Window";
import Modal from "@material-ui/core/Modal";
import Image from "react-bootstrap/Image";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import FolderIcon from "@material-ui/icons/Folder";
import Avatar from "@material-ui/core/Avatar";
import { formRow } from "aws-amplify";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  noteSection: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr",
    gridGap: "1.5rem",
    "@media (max-width: 500px)": {
      display: "block",
    },
  },
  paddingHeading: {
    padding: "30px 30px 0px 30px",
    fontSize: "20px",
    "@media (max-width: 768px)": {
      padding: "1rem 1rem 0rem 3rem",
    },
  },
  paddingBody: {
    backgroundColor: "white",
    height: "19rem",
    margin: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    "@media (max-width: 426px)": {
      width: "24rem",
    },
  },
  buttonConfirm: {
    position: "relative",
    right: "40px",
    top: "70px",
    color: "#3372B5",
    backgroundColor: "white",
    borderRadius: "8px",
    fontSize: "15px",
    height: "28px",
    lineHeight: "13px",
    float: "right",
    width: "100px",
    border: "2px solid #3372B5",
    "@media (max-width: 570px)": {
      width: "fit-content",
    },
  },
  buttonCancle: {
    position: "relative",
    right: "-28px",
    top: "55px",
    color: "lightcoral",
    backgroundColor: "white",
    borderRadius: "8px",
    fontSize: "15px",
    height: "28px",
    lineHeight: "13px",
    float: "left",
    width: "100px",
    border: "2px solid lightcoral",
    "@media (max-width: 570px)": {
      width: "fit-content",
    },
  },
}));

const MiniAssignment = (props) => {
  let {
    autoScorCal,
    miniloader,
    setMiniloader,
    saveResponse,
    Button,
    imgUrl,
    produce,
    //classes,
    getQuiz,
    btnClick,
    setScore,
    curObject,
    showScore,
    miniScore,
    sMiniScore,
    assignment,
    handleClose,
    quizQuestion,
    setShowScore,
    isSubmittingg,
    setQIsLoading,
    assignmentLoad,
    currentQuestion,
    setAssignmentLoad,
    setCurrentQuestion,
    handleImageChange,
    setQuizQuestion,
    handleMiniDesOption,
    answerNext,
    setAnsFcount,
    ansFcount,
    setCheckbox,
    miniopen,
    setMiniopen,
    totalminiScore,
    correct,
    answerPrev,
    questionArray,
    setQuestionArray,
    pissueTextUpload,
    setPIssueTextUpload,
    deadLine,
    setDeadLine,
  } = props;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
  const { height, width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [obj, setObj] = useState();
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const currentDate = new Date();

  useEffect(() => {
    console.log("Mounted MiniAssignment Quize");
    console.log("current Date", currentDate);
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function confirmSave() {
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={miniopen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={miniopen}>
            <div className={classes.paddingBody}>
              <h3 className={classes.paddingHeading}>
                Are you sure you want to submit?
              </h3>
              <button
                className={classes.buttonConfirm}
                onClick={() => {
                  setMiniloader(true);
                  if (
                    obj.miniassignmenttype != undefined &&
                    (obj.miniassignmenttype == "1" ||
                      obj.miniassignmenttype == 1)
                  ) {
                    autoScorCal(data, obj);
                  } else {
                    saveResponse(data, obj);
                  }
                }}
              >
                Confirm
              </button>
              <br />
              <button
                className={classes.buttonCancle}
                onClick={() => {
                  // setBtnClick(false);
                  //getQuiz(curObject);
                  /*
                setScore(0);
                setShowScore(false);
                setCurrentQuestion(0); */
                  setMiniopen(false);
                  setScore(0);
                  setShowScore(true);
                  setCurrentQuestion(0);
                }}
              >
                Cancel
              </button>
            </div>
          </Fade>
        </Modal>
      </>
    );
    const handleClosePop = () => {
      setMiniopen(false);
    };
  }

  return (
    <div
      className="body"
      style={{ height: btnClick || showScore ? height - 400 : "" }}
    >
      {confirmSave()}
      {btnClick ? (
        <div className="app1">
          {showScore ? (
            <div className="score-section">
              <Backdrop className={classes.backdrop} open={miniloader}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <h3>Goto Home Page</h3>
              <div>
                <button
                  className="buttonQuizCt"
                  onClick={() => {
                    handleClose();
                    setScore(0);
                    setCurrentQuestion(0);
                    getQuiz(curObject);
                  }}
                >
                  Exit Assignment
                </button>
              </div>
            </div>
          ) : (
            <>
              {deadLine ? (
                <div style={{ margin: "auto" }}>
                  <h1>DeadLine is Over for Submission Of Assignment</h1>
                </div>
              ) : (
                <div className="scrollQuiz">
                  <div className="question-section">
                    <div className="question-count">
                      <span>Question {currentQuestion + 1}</span>/
                      {quizQuestion.length}
                    </div>

                    {quizQuestion[currentQuestion].atype == 7 ? (
                      <>
                        <div
                          className="question-text"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {questionArray[currentQuestion].map((Qitems) => {
                            return (
                              <div style={{ margin: "5px" }}>
                                {Qitems == "#" ? (
                                  <>
                                    <select
                                      className="boxselect"
                                      //  value={selectedOption}
                                      // onChange={(e) =>
                                      //   handleOptionChange(e, currentQuestion)
                                      // }

                                      onChange={(e) => {
                                        setQuizQuestion((currentQues) =>
                                          produce(currentQues, (v) => {
                                            v[currentQuestion].response =
                                              e.target.value;
                                          })
                                        );
                                      }}
                                      style={{
                                        width: "20rem",
                                        height: "3rem",
                                        padding: "5px",
                                        border: "1px solid #ccc",
                                        fontSize: "12px",
                                        margin: "auto",
                                      }}
                                    >
                                      <option value="">--Select--</option>
                                      {quizQuestion[currentQuestion].iopts.map(
                                        (option1, index) => (
                                          <option key={index} value={index}>
                                            {option1.content}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </>
                                ) : (
                                  <> {Qitems}</>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <div
                        className="question-text"
                        dangerouslySetInnerHTML={{
                          __html: quizQuestion[currentQuestion].istem,
                        }}
                      >
                        {/* {quizQuestion[currentQuestion].istem} */}
                      </div>
                    )}
                  </div>
                  <div>
                    {!quizQuestion[currentQuestion].iopts ||
                      quizQuestion[currentQuestion].iopts.length === 0 ? (
                      <div className={classes.root}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: `${quizQuestion[currentQuestion].imageurl
                                ? "4fr"
                                : "1fr"
                              } `,
                            gridGap: "1.5rem",
                          }}
                        >
                          <center>
                            <div style={{ width: "auto" }}>
                              {quizQuestion[currentQuestion].imageurl ? (
                                <div className="img_container">
                                  <img
                                    src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                    alt="item"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </center>
                          <br></br>

                          <div className={classes.root}>
                            <TextField
                              fullWidth
                              className={classes.textarea11}
                              value={
                                quizQuestion[currentQuestion].response ==
                                  undefined ||
                                  quizQuestion[currentQuestion].response.length ==
                                  0
                                  ? ""
                                  : quizQuestion[currentQuestion].response
                              }
                              placeholder="Type your response here..."
                              multiline
                              rows={4}
                              name="response"
                              label="Insert some text"
                              onChange={(e) => {
                                setQuizQuestion((currentQues) =>
                                  produce(currentQues, (v) => {
                                    v[currentQuestion].response =
                                      e.target.value;
                                    v[currentQuestion].miniType = 2;
                                  })
                                );
                                // console.log(
                                //   "i come",
                                //   JSON.stringify(quizQuestion[currentQuestion])
                                // );
                              }}
                              variant="outlined"
                              color="primary"
                            />
                            <div className={classes.noteSection}>
                              <div
                                style={{
                                  padding: "5%",
                                  fontSize: "14px",
                                }}
                              >
                                <p style={{ textAlign: "justify" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Note:
                                  </span>{" "}
                                  1. Please upload files in the format of pdf,
                                  jpg, jpeg, png only.
                                  <p>
                                    2. Please do not upload any images or PDFs
                                    that contain handwritten text.
                                  </p>
                                  {/* <p>
                                    2. file size should not be more than 250kb.
                                  </p>
                                  <p>3. Clear images should be uploaded.</p>
                                  <a
                                    href={`https://${
                                      Constants.DOMAIN
                                    }/${config.aws_org_id.toLowerCase()}-resources/pdf/handwritten-abstract.png`}
                                    target="blank"
                                    style={{
                                      //fontSize: "16px",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Please click here to check for referal
                                    image.
                                  </a> */}
                                </p>
                              </div>
                              <div
                                style={{ paddingTop: "5%", fontSize: "14px" }}
                              >
                                <input
                                  className={classes.hidden}
                                  // id={files.name}
                                  // name={files.name}
                                  accept="image/*,application/pdf,"
                                  type="file"
                                  onChange={handleImageChange}
                                  ref={hiddenFileInput}
                                  style={{ display: "none" }}
                                />

                                <Avatar
                                  style={{
                                    background: "#3f51b5",
                                    height: "56px",
                                    width: "56px",
                                  }}
                                  onClick={handleClick}
                                >
                                  <FolderIcon />
                                </Avatar>

                                <Typography
                                  className={classes.title}
                                  variant="h6"
                                ></Typography>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    paddingLeft: "3%",
                                  }}
                                >
                                  {quizQuestion[currentQuestion]
                                    .uploadedfilename == undefined ||
                                    quizQuestion[currentQuestion].uploadedfilename
                                      .length == 0
                                    ? "Browse"
                                    : quizQuestion[currentQuestion]
                                      .uploadedfilename}
                                </p>
                              </div>
                              <div>
                                {/* <p
                              style={{
                                color: "red",
                                paddingRight: "30%",
                                marginTop: "20%",
                                fontSize: "12px",
                              }}
                            >
                              {isSubmittingg
                                ? " Please upload files in the format of pdf, jpg, jpeg, png , Docs only."
                                : ""}
                            </p> */}
                              </div>
                            </div>
                          </div>
                          <div>
                            <p style={{ color: "red", fontWeight: "bold" }}>
                              {pissueTextUpload}
                            </p>
                          </div>
                          <div
                            style={{
                              marginTop: "-1%",
                              paddingLeft: "3%",
                              paddingRight: "3%",
                            }}
                          >
                            {currentQuestion > 0 ? (
                              <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                className="btn-size"
                                onClick={() => {
                                  answerPrev();
                                }}
                                style={{ fontSize: "12px" }}
                              >
                                Prev
                              </Button>
                            ) : null}
                            {currentQuestion + 1 === quizQuestion.length ? (
                              <Button
                                disabled={
                                  quizQuestion[currentQuestion].response ==
                                  undefined ||
                                  (quizQuestion[currentQuestion].response
                                    .length == 0 &&
                                    isSubmittingg == true)
                                }
                                color="primary"
                                variant="contained"
                                fullWidth
                                className="btn-siz"
                                onClick={() => {
                                  setData(quizQuestion);
                                  setObj(curObject);
                                  setMiniopen(true);
                                  // confirmSave(quizQuestion,curObject)

                                  // saveResponse(quizQuestion,curObject)
                                }}
                                style={{ float: "right", fontSize: "12px" }}
                              >
                                {isSubmittingg ? "Loading..." : "Submit"}
                              </Button>
                            ) : (
                              <Button
                                disabled={
                                  quizQuestion[currentQuestion].response ==
                                  undefined ||
                                  quizQuestion[currentQuestion].response
                                    .length == 0 ||
                                  isSubmittingg == true
                                }
                                color="primary"
                                variant="contained"
                                fullWidth
                                className="btn-siz"
                                onClick={() => {
                                  answerNext();
                                }}
                                style={{ float: "right", fontSize: "12px" }}
                              >
                                {isSubmittingg ? "Loading..." : "Next"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="answer-section">
                        <center>
                          <div style={{ width: "auto" }}>
                            {quizQuestion[currentQuestion].imageurl ? (
                              <div className="img_container">
                                <img
                                  src={`${imgUrl}${quizQuestion[currentQuestion].imageurl}`}
                                  alt="item"
                                />
                              </div>
                            ) : null}
                          </div>
                        </center>
                        <>
                          {quizQuestion[currentQuestion].atype == 3 ||
                            quizQuestion[currentQuestion].atype == 5 ? (
                            <div className="answersection">
                              {/* <FormControl component="fieldset"> */}
                              <FormGroup
                                aria-label="position"
                                column
                                style={{ paddingLeft: "3%" }}
                              >
                                {quizQuestion[currentQuestion].iopts.map(
                                  (answerOption, index, arrayobj) => (
                                    <>
                                      {quizQuestion[currentQuestion].atype ==
                                        5 ? (
                                        <>
                                          <div>
                                            <FormControlLabel
                                              className="buttonQuiz"
                                              value={
                                                <Image
                                                  src={
                                                    imgUrl + answerOption.url
                                                  }
                                                  rounded
                                                />
                                              }
                                              control={
                                                <Checkbox
                                                  color="primary"
                                                  checked={
                                                    answerOption.checked !=
                                                    undefined &&
                                                    (answerOption.checked ==
                                                      true ||
                                                      answerOption.checked ==
                                                      "true")
                                                  }
                                                />
                                              }
                                              label={
                                                <Image
                                                  src={
                                                    imgUrl + answerOption.url
                                                  }
                                                  rounded
                                                />
                                              }
                                              onChange={(e) => {
                                                setCheckbox(
                                                  index,
                                                  e.target.checked
                                                );
                                              }}
                                              labelPlacement="end"
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <FormControlLabel
                                            className="buttonQuiz"
                                            value={answerOption.content}
                                            control={
                                              <Checkbox
                                                color="primary"
                                                checked={
                                                  answerOption.checked !=
                                                    undefined &&
                                                    (answerOption.checked ==
                                                      true ||
                                                      answerOption.checked ==
                                                      "true")
                                                    ? true
                                                    : false
                                                }
                                              />
                                            }
                                            label={answerOption.content}
                                            onChange={(e) => {
                                              setCheckbox(
                                                index,
                                                e.target.checked
                                              );
                                            }}
                                            labelPlacement="end"
                                          />
                                        </>
                                      )}
                                    </>
                                  )
                                )}
                              </FormGroup>
                              {/* </FormControl> */}
                            </div>
                          ) : (
                            <>
                              {quizQuestion[currentQuestion].atype == 7 ? (
                                <>
                                  {/* <FormControl variant="standard">
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              style={{ fontSize: "medium" }}
                            >
                              Answer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              //  value={age}
                              //   onChange={handleChange}
                              defaultValue={
                                quizQuestion[currentQuestion].response !==
                                undefined
                                  ? quizQuestion[currentQuestion].response
                                  : null
                              }
                              onChange={(e) => {
                                setQuizQuestion((currentQues) =>
                                  produce(currentQues, (v) => {
                                    v[currentQuestion].response =
                                      e.target.value;
                                  })
                                );
                              }}
                              label="Age"
                              style={{ fontSize: "medium", width: "50%" }}
                            >
                              {quizQuestion[currentQuestion].iopts.map(
                                (answerOption, index, arrayobj) => (
                                  <MenuItem
                                    style={{ fontSize: "medium" }}
                                    key={index}
                                    value={index}
                                  >
                                    {answerOption.content}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl> */}
                                </>
                              ) : (
                                <>
                                  {quizQuestion[currentQuestion].iopts.map(
                                    (answerOption, index, arrayobj) => (
                                      <>
                                        {quizQuestion[currentQuestion].atype ==
                                          4 ? (
                                          <>
                                            <div>
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name="options"
                                                id={answerOption.content}
                                              />
                                              <label
                                                className="buttonQuiz"
                                                for={answerOption.content}
                                                onClick={() =>
                                                  handleMiniDesOption(
                                                    answerOption.correct,
                                                    index
                                                  )
                                                }
                                              >
                                                <Image
                                                  src={
                                                    imgUrl + answerOption.url
                                                  }
                                                  rounded
                                                />
                                              </label>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {/* <input
                                      type="radio"
                                      className="btn-check"
                                      name="options"
                                      id={answerOption.content}
                                      style={{ borderColor:'red'  }}

                                      // style={{ borderColor: answerOption.Myselection == 'true'?'#0f80a4' : '' }}

                                    />
                                    <label
                                      className="buttonQuiz"
                                      for={answerOption.content}
                                      onClick={() =>
                                        handleMiniDesOption(
                                          answerOption.correct,
                                          index
                                        )
                                      }
                                    >
                                      {answerOption.content},kkk
                                    </label> */}
                                            <button
                                              key={"a" + index}
                                              // className="buttonQuiz"
                                              className={`buttonQuiz`}
                                              data-id={`${answerOption.correct == true
                                                  ? answerOption.correct
                                                  : null
                                                }`}
                                              style={{
                                                borderColor:
                                                  answerOption.Myselection ==
                                                    "true"
                                                    ? "#0f80a4"
                                                    : "",
                                              }}
                                              onClick={() => {
                                                if (
                                                  quizQuestion[currentQuestion]
                                                    .Qsubmit == undefined
                                                ) {
                                                  setAnsFcount(ansFcount + 1);
                                                }
                                                setQuizQuestion((currentQues) =>
                                                  produce(currentQues, (v) => {
                                                    v[currentQuestion].Qsubmit =
                                                      "true";
                                                    v[
                                                      currentQuestion
                                                    ].iopts.forEach((opt) => {
                                                      opt.Myselection = "false";
                                                    });
                                                    v[currentQuestion].iopts[
                                                      index
                                                    ].Myselection = "true";
                                                  })
                                                );
                                                handleMiniDesOption(
                                                  answerOption,
                                                  index
                                                );
                                                console.log(
                                                  "ans",
                                                  answerOption,
                                                  index
                                                );
                                              }}
                                            >
                                              {answerOption.content}
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                        <div
                          style={{
                            padding: "3%",
                          }}
                        >
                          {currentQuestion > 0 ? (
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-size"
                              onClick={() => {
                                answerPrev();
                              }}
                              style={{ fontSize: "12px" }}
                            >
                              Prev
                            </Button>
                          ) : null}
                          {currentQuestion + 1 === quizQuestion.length ? (
                            <Button
                              disabled={
                                quizQuestion[currentQuestion].response ==
                                undefined ||
                                (quizQuestion[currentQuestion].response
                                  .length == 0 &&
                                  isSubmittingg == true)
                              }
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-siz"
                              onClick={() => {
                                setData(quizQuestion);
                                setObj(curObject);
                                setMiniopen(true);
                                // confirmSave(quizQuestion,curObject)

                                // saveResponse(quizQuestion,curObject)
                              }}
                              style={{ float: "right", fontSize: "12px" }}
                            >
                              {isSubmittingg ? "Loading..." : "Submit"}
                            </Button>
                          ) : (
                            <Button
                              disabled={
                                quizQuestion[currentQuestion].response ==
                                undefined ||
                                quizQuestion[currentQuestion].response.length ==
                                0 ||
                                isSubmittingg == true
                              }
                              color="primary"
                              variant="contained"
                              fullWidth
                              className="btn-siz"
                              onClick={() => {
                                answerNext();
                              }}
                              style={{ float: "right", fontSize: "12px" }}
                            >
                              {isSubmittingg ? "Loading..." : "Next"}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          {assignment ? (
            assignmentLoad ? (
              <CircularProgress color="default" size={30} />
            ) : navigator.onLine ? (
              <button
                className="btnQuizLD"
                onClick={() => {
                  getQuiz(curObject);
                  setShowScore(false);
                  setQIsLoading(true);
                  setAssignmentLoad(true);
                }}
              >
                Assignment
              </button>
            ) : (
              <p>you are offline</p>
            )
          ) : (
            <div>
              {sMiniScore ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <h1
                    style={{
                      color: "#000",
                      textAlign: "center",
                      margin: "2.5rem 0",
                      boxShadow: "0 0 0 5px #e35f14",
                      borderRadius: "100%",
                      height: "100px",
                      width: "100px",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {miniScore}/{totalminiScore}
                    </span>
                  </h1>
                </div>
              ) : null}
              <div style={{ color: "black" }}>
                {assignmentLoad ? (
                  <CircularProgress color="default" size={30} />
                ) : (
                  <h1>Assignment submitted..!</h1>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MiniAssignment;
